<template>
    <div class="container">
        <h3>邮件发送情况</h3>
        <div class="coupon-generate">
            <div class="date">
                <p>发送日期:</p>
                <el-date-picker unlink-panels size="default" style="height: 32px" v-model="dateValue" type="daterange"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts"
                    :default-value="[new Date(2024, 6, 1), new Date(2024, 7, 1)]" />
            </div>
            <div class="type">
                <p>邮件类型:</p>
                <el-select v-model="emailTypeValue" clearable placeholder="请选择邮件类型" style="width: 240px">
                    <el-option v-for="item in emailOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <el-button type="primary" @click="winBackEmailSend()">筛选</el-button>
        </div>
        <div class="data-text">
            <p>共发送: {{ sendNum }}封</p>
            <p>共打开: {{ openNum }}封</p>
            <p>打开率: {{ openRate }}</p>
            <p>共点击: {{ clickNum }}次</p>
            <p>点击率: {{ clickRate }}</p>
            <p>邮件被投诉次数: {{ complaintNum }}次</p>
            <p>投诉率: {{ complaintRate }}</p>
            <p>邮件退回次数: {{ returnNum }}次</p>
            <p>退回率: {{ returnRate }}</p>
        </div>
        <h3>赢回记录</h3>
        <div class="remark-text">
            <el-tooltip class="box-item" effect="dark" placement="top-start">
                <template #content>
                    <div>
                        <ol>
                            <li>赢回记录是赢回用户的记录，赢回用户产生了付费。</li>
                            <li>
                                赢回记录可以通过<b>套餐</b>、<b>Activity</b>、<b>来源</b>和<b>支付日期</b>,
                                也可以通过邮件地址查询。
                            </li>
                            <li>
                                Activity: 包括onlineprivacy, staysafenow, backtosafety<br />
                                onlineprivacy: 用户订阅过期当日, 发送该优惠折扣。<br />
                                staysafenow: 用户订阅过期 7 天发送该优惠折扣。<br />
                                backtosafety: 用户订阅过期 1 个月发送该优惠折扣。
                            </li>
                            <li>
                                首次赢回费用是指，用户通过赢回邮件折扣订阅时的付费，不包括后续续订或者订阅的费用。
                            </li>
                            <li>
                                累计赢回费用是指，用户通过赢回邮件订阅后，并与之关联产生的所有付费，包括首次赢回的费用。
                            </li>
                            <li>
                                来源是指，用户通过邮件内按钮 或者 用户中心的 renew 按钮完成订阅。
                            </li>
                            <li>
                                付费次数是指，用户通过赢回邮件订阅后，并与之关联产生的所有付费的次数，包括首次赢回。
                            </li>
                            <li>
                                赢回总收入是指，用户通过赢回邮件订阅的收入，包括与之关联的后续续订个订阅收入。
                            </li>
                            <li>可以通过切换图标和表格查看总览和详细信息。</li>
                        </ol>
                    </div>
                </template>
                <el-button style="color: red">备注</el-button>
            </el-tooltip>
        </div>
        <div class="issuance-record">
            <div class="status">
                <p>套餐:</p>
                <el-select v-model="comboValue" clearable placeholder="请选择套餐类型" style="width: 240px">
                    <el-option v-for="item in comboOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="type">
                <p>Activity:</p>
                <el-select v-model="emailTypeValue2" clearable placeholder="请选择Activity类型" style="width: 240px">
                    <el-option v-for="item in emailOptions2" :key="item.value" :label="item.label"
                        :value="item.value" />
                </el-select>
            </div>
            <div class="type">
                <p>来源:</p>
                <el-select v-model="fromValue" clearable placeholder="请选择来源类型" style="width: 240px">
                    <el-option v-for="item in fromOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="date">
                <p>支付日期:</p>
                <el-date-picker unlink-panels size="default" style="height: 32px" v-model="payDateValue"
                    type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    :shortcuts="payShortcuts" :default-value="[new Date(2024, 6, 1), new Date(2024, 7, 1)]" />
            </div>
            <div class="email">
                <p>用户邮箱:</p>
                <el-input v-model="emailInput" style="width: 240px" placeholder="请输入用户邮箱" clearable />
            </div>
            <el-button type="primary" @click="winBackEmailBuy()">查询</el-button>
        </div>
        <el-tabs v-model="activeName" type="card" class="demo-tabs">
            <el-tab-pane label="图表" name="first" :style="chartData1 ? 'min-height: 1000px ' : ''"
                style="display: flex; flex-wrap: wrap; flex-direction: column">

                <el-row>
                    <el-col :span="4" style="padding:10px">
                        <div>
                            赢回用户数: <span style="color: red;">{{ winBackSum }}</span>
                        </div>
                    </el-col>
                    <el-col :span="4" style="padding:10px">
                        <div>
                            赢回总付费次数: <span style="color: red;">{{ winBackFrequency }}</span>
                        </div>
                    </el-col>
                    <el-col :span="4" style="padding:10px">
                        <div>
                            赢回总收入: <span style="color: red;">{{ winBackAccount }} USD</span>
                        </div>
                    </el-col>
                </el-row>

                <div style="display: flex">
                    <el-row id="winBackPayWayPie"> </el-row>
                    <el-row id="winBackSourcePie"> </el-row>
                </div>
                <div style="display:flex">
                    <el-row id="winBackPayWaySumPie">
                    </el-row>
                    <el-row id="winBackSourceSumPie">
                    </el-row>
                </div>

                <div style="display:flex">
                    <el-row id="winBackUserSumPie">
                    </el-row>
                    <el-row id="winBackActivPie">
                    </el-row>
                </div>
            </el-tab-pane>
            <el-tab-pane label="表格" name="second">
                <el-table v-if="activeName == 'second'" v-loading="loading" :data="tableData" border
                    style="width: 100%; max-height: 400px; overflow-y: auto">
                    <el-table-column width="250" sortable prop="email" label="Email" />
                    <el-table-column sortable prop="startTime" label="赢回日期" />
                    <el-table-column sortable prop="sku" label="套餐" />
                    <el-table-column sortable prop="activity" label="Activity" />
                    <el-table-column sortable prop="actualPriceAmount" label="首次赢回费用" />
                    <el-table-column sortable prop="totalPriceAmount" label="累计赢回费用" />
                    <el-table-column width="200" sortable prop="platform" label="支付平台" />
                    <el-table-column sortable prop="pay_source" label="来源" />
                    <el-table-column sortable prop="pay_nums" label="付费次数" />
                </el-table>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import {
    win_back_email_send,
    win_back_email_buy,
} from "../services/requests.js";
import { b64decode } from "../utils/way.js";
import { ElMessage, ElLoading } from "element-plus";

import * as echarts from "echarts";

var activeName = ref("first");

var chartData1 = ref("");


const emailInput = ref("");
const emailTypeValue = ref("All");
const emailTypeValue2 = ref("All");
const comboValue = ref("All");
const fromValue = ref("All");
const end = new Date();
const payEnd = new Date();
const start = new Date();
const payStart = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
payStart.setTime(payStart.getTime() - 3600 * 1000 * 24 * 7);
const dateValue = ref([start, end]);
const payDateValue = ref([payStart, payEnd]);
const tableData = reactive([]);
const loading = ref(false);
const sendNum = ref(0);
const openNum = ref(0);
const clickNum = ref(0);
const complaintNum = ref(0);
const returnNum = ref(0);
const openRate = ref("0%");
const clickRate = ref("0%");
const complaintRate = ref("0%");
const returnRate = ref("0%");
const winBackSum = ref(0);
const winBackAccount = ref(0);
const winBackFrequency = ref(0);


const fromOptions = [
    {
        value: "All",
        label: "全部",
    },
    {
        value: "email",
        label: "邮件",
    },
    {
        value: "web",
        label: "用户中心",
    },
];
const comboOptions = [
    {
        value: "All",
        label: "全部",
    },
    {
        value: "monthly",
        label: "monthly",
    },
    {
        value: "quarterly",
        label: "quarterly",
    },
    {
        value: "yearly",
        label: "yearly",
    },
];
const emailOptions = [
    {
        value: "All",
        label: "全部",
    },
    {
        value: "onlineprivacy",
        label: "onlineprivacy",
    },
    {
        value: "staysafenow",
        label: "staysafenow",
    },
    {
        value: "backtosafety",
        label: "backtosafety",
    },
];

const emailOptions2 = [
    {
        value: "All",
        label: "全部",
    },
    {
        value: "onlineprivacy",
        label: "onlineprivacy",
    },
    {
        value: "staysafenow",
        label: "staysafenow",
    },
    {
        value: "backtosafety",
        label: "backtosafety",
    },
];

const payShortcuts = [
    {
        text: "过去一周",
        value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
        },
    },
    {
        text: "过去一个月",
        value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
        },
    },
    {
        text: "过去三个月",
        value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
        },
    },
    {
        text: "所有时间",
        value: () => {
            const start = new Date("2024-01-01");
            const end = new Date(); // 当前日期
            return [start, end];
        },
    },
];

const shortcuts = [
    {
        text: "过去一周",
        value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
        },
    },
    {
        text: "过去一个月",
        value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
        },
    },
    {
        text: "过去三个月",
        value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
        },
    },
    {
        text: "所有时间",
        value: () => {
            const start = new Date("2024-01-01");
            const end = new Date(); // 当前日期
            return [start, end];
        },
    },
];

const winBackEmailSend = () => {
    const loading1 = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
    });
    var startTime = formatDate(dateValue.value[0]);
    var endTime = formatDate(dateValue.value[1]);
    win_back_email_send(startTime, endTime, emailTypeValue.value)
        .then((res) => {
            console.log(res);
            var data = b64decode(res.data);
            var code = data.code;
            if (code == 200) {
                loading1.close();
                sendNum.value = data.data.send;
                openNum.value = data.data.open;
                clickNum.value = data.data.click;
                returnNum.value = data.data.bounce;
                complaintNum.value = data.data.complaint;
                if (data.data.complaint_rate != "NA") {
                    complaintRate.value =
                        (data.data.complaint_rate * 100).toFixed(2) + "%";
                } else {
                    complaintRate.value = data.data.complaint_rate;
                }
                if (data.data.open_rate != "NA") {
                    openRate.value = (data.data.open_rate * 100).toFixed(2) + "%";
                } else {
                    openRate.value = data.data.open_rate;
                }
                if (data.data.click_rate != "NA") {
                    clickRate.value = (data.data.click_rate * 100).toFixed(2) + "%";
                } else {
                    clickRate.value = data.data.click_rate;
                }
                if (data.data.bounce_rate != "NA") {
                    returnRate.value = (data.data.bounce_rate * 100).toFixed(2) + "%";
                } else {
                    returnRate.value = data.data.bounce_rate;
                }
                ElMessage({
                    message: `邮件发送情况查询成功`,
                    type: "success",
                });
            } else {
                loading1.close();
                ElMessage({
                    message: `查询失败, 请重试`,
                    type: "error",
                });
            }
        })
        .catch((err) => {
            ElMessage({
                message: err,
                type: "error",
            });
        });
};

const load_pie_chart = (chart_id, title, data) => {
    const id_array = ["winBackPayWaySumPie", "winBackSourceSumPie"]
    let unit = '次'
    if (id_array.includes(chart_id)) {
        unit = "美元"
    }
    var chartDom = document.getElementById(chart_id);
    var myChart = echarts.getInstanceByDom(chartDom);
    if (myChart) {
        myChart.dispose(); // 销毁现有实例
    }
    // console.log(chart_id, title, "加载数据", data);
    // 获取屏幕宽度
    var screen_width = document.body.clientWidth;
    var width = screen_width / 3;
    var height = 400;
    chartDom.style.width = width + "px";
    chartDom.style.height = height + "px";
    myChart = echarts.init(chartDom);
    var option = {
        title: {
            text: title,
            left: "center",
        },
        tooltip: {
            trigger: "item",
        },
        legend: {
            orient: "vertical",
            left: "left",
        },
        series: [
            {
                type: "pie",
                radius: "50%",
                data: data,
                label: {
                    normal: {
                        show: true,
                        formatter: `{b}: {c}${unit} ( {d}% )`,
                    },
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
            },
        ],
    };
    console.log(title, option);
    myChart.setOption(option);
    myChart.resize(width, height);
};

const winBackEmailBuy = () => {
    activeName.value = "first";
    console.log(activeName)
    const loading1 = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
    });
    var startTime = formatDate(payDateValue.value[0]);
    var endTime = formatDate(payDateValue.value[1]);
    win_back_email_buy(
        startTime,
        endTime,
        emailTypeValue2.value,
        comboValue.value,
        fromValue.value,
        emailInput.value
    )
        .then((res) => {
            var data = b64decode(res.data);
            var code = data.code;
            if (code == 200) {
                loading1.close();
                tableData.length = 0;
                winBackAccount.value = 0;
                winBackFrequency.value = 0;
                var winBackPayWayPie = [];
                var winBackSourcePie = [];
                var winBackPayWaySumPie = [];
                var winBackSourceSumPie = [];
                var winBackUserSumPie = [];
                var winBackActivPie = [];
                // 创建一个 Map 来统计 platform 的出现次数
                const platformCount = new Map();
                const platformSumCount = new Map();
                const sourceCount = new Map();
                const sourceSumCount = new Map();
                const sourceUserCount = new Map([
                    ['邮件', 0],
                    ['用户中心', 0]
                ]);
                const activityCount = new Map();
                for (let i = 0; i < data.data.length; i++) {
                    if (data.data[i].sku == "yearly") {
                        data.data[i].sku = "年";
                    } else if (data.data[i].sku == "quarterly") {
                        data.data[i].sku = "季";
                    } else if (data.data[i].sku == "monthly") {
                        data.data[i].sku = "月";
                    }
                    if (data.data[i].pay_source == "email") {
                        data.data[i].pay_source = "邮件";
                    } else {
                        data.data[i].pay_source = "用户中心";
                    }
                    winBackAccount.value += Number(data.data[i].totalPriceAmount);
                    winBackFrequency.value += Number(data.data[i].pay_nums);
                    tableData.push(data.data[i]);
                }
                data.data.forEach((item) => {
                    const activity = item.activity;
                    if (activityCount.has(activity)) {
                        activityCount.set(activity, activityCount.get(activity) + 1);
                    } else {
                        activityCount.set(activity, 1);
                    }

                    if (item.pay_source == "邮件") {
                        sourceUserCount.set(
                            "邮件",
                            sourceUserCount.get("邮件") + item.pay_nums
                        );
                    } else {
                        sourceUserCount.set(
                            "用户中心",
                            sourceUserCount.get("用户中心") + item.pay_nums
                        );
                    }

                    const platform = item.platform;
                    if (platformCount.has(platform)) {
                        platformCount.set(platform, platformCount.get(platform) + 1);
                    } else {
                        platformCount.set(platform, 1);
                    }

                    if (platformSumCount.has(platform)) {
                        platformSumCount.set(
                            platform,
                            platformSumCount.get(platform) + item.totalPriceAmount
                        );
                    } else {
                        platformSumCount.set(platform, item.totalPriceAmount);
                    }

                    const pay_source = item.pay_source;
                    if (sourceCount.has(pay_source)) {
                        sourceCount.set(pay_source, sourceCount.get(pay_source) + 1);
                    } else {
                        sourceCount.set(pay_source, 1);
                    }

                    if (sourceSumCount.has(pay_source)) {
                        sourceSumCount.set(
                            pay_source,
                            sourceSumCount.get(pay_source) + item.totalPriceAmount
                        );
                    } else {
                        sourceSumCount.set(pay_source, item.totalPriceAmount);
                    }
                });

                platformCount.forEach((count, platform) => {
                    let obj = { name: platform, value: count };
                    winBackPayWayPie.push(obj);
                });

                sourceCount.forEach((count, pay_source) => {
                    let obj = { name: pay_source, value: count };
                    winBackSourcePie.push(obj);
                });

                platformSumCount.forEach((count, platform) => {
                    let obj = { name: platform, value: count.toFixed(2) };
                    winBackPayWaySumPie.push(obj);
                });

                sourceSumCount.forEach((count, pay_source) => {
                    let obj = { name: pay_source, value: count.toFixed(2) };
                    winBackSourceSumPie.push(obj)
                });
                sourceUserCount.forEach((value, key) => {
                    if (value != 0) {
                        let obj = { name: key, value: value };
                        winBackUserSumPie.push(obj)
                    }
                });

                activityCount.forEach((value, key) => {
                    let obj = { name: key, value: value };
                    winBackActivPie.push(obj)
                });

                winBackAccount.value = winBackAccount.value.toFixed(2);
                winBackSum.value = tableData.length;

                load_pie_chart(
                    "winBackPayWayPie",
                    "赢回总付费次数-支付方式",
                    winBackPayWayPie
                );
                load_pie_chart(
                    "winBackSourcePie",
                    "赢回用户数",
                    winBackSourcePie
                );

                load_pie_chart(
                    "winBackPayWaySumPie",
                    "赢回总收入-支付方式",
                    winBackPayWaySumPie
                );

                load_pie_chart(
                    "winBackSourceSumPie",
                    "赢回总收入-来源",
                    winBackSourceSumPie
                );

                load_pie_chart(
                    "winBackUserSumPie",
                    "赢回总付费次数-来源",
                    winBackUserSumPie
                );

                load_pie_chart(
                    "winBackActivPie",
                    "赢回活动占比",
                    winBackActivPie
                );

                chartData1.value = winBackPayWayPie

                ElMessage({
                    message: `赢回记录查询成功`,
                    type: "success",
                });
            } else {
                loading1.close();
                ElMessage({
                    message: `查询失败, 请重试`,
                    type: "error",
                });
            }
        })
        .catch((err) => {
            ElMessage({
                message: err,
                type: "error",
            });
        });
};

const formatDate = (dateString) => {
    const date = new Date(dateString);

    // 获取年份、月份和日期
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以需要加1
    const day = ("0" + date.getDate()).slice(-2);

    // 拼接成 YYYY-MM-DD 格式
    return `${year}-${month}-${day}`;
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart-container p {
    font-size: 18px;
    font-weight: bold;
}

.pie-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.remark-text {
    text-align: left;
    margin-top: 10px;
}

.remark-text ol li {
    line-height: 30px;
}

.cell-remark {
    display: inline-block;
    max-width: 200px;
    /* 根据需要调整宽度 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.status-container {
    display: flex;
    align-items: center;
    justify-content: left;
}

.status-container p {
    margin-right: 20px;
}

.container {
    width: 1400px;
    padding: 20px;
    margin: 0 auto;
}

.data-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.data-text-two {
    flex-direction: column;
    font-weight: bold;
    border: 0px;
}

.coupon-generate,
.issuance-record {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.email,
.type,
.remark,
.status,
.date {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.email p,
.type p,
.remark p,
.status p,
.date p {
    margin-right: 10px;
}

h3 {
    text-align: left;
    margin: 0px;
}
</style>