<template>
  <div v-if="show_details">
    <div v-if="campaign_show">
      <el-table
        id="fb_campaign_table"
        :data="campaign_plan_data"
        style="width: 100%"
        @expand-change="handleExpandChange"
        :default-sort="{ prop: 'new_day', order: 'descending' }"
        :cell-style="fb_cellStyle"
        v-loading="campaign_plan_loading"
        element-loading-text="加载中..."
      >
        <el-table-column type="expand">
          <template #default="props">
            <el-table
              :data="props.row.day_details"
              style="width: 100%"
              :fit="true"
            >
              <el-table-column prop="campaign_id" label="广告系列" sortable />
              <el-table-column prop="campaign_name" label="日期" sortable />
              <el-table-column prop="new_day" label="新增设备" sortable />
              <el-table-column prop="details" label="详情" :min-width="500">
                <template #default="scope">
                  <el-table :data="scope.row.details" :fit="true">
                    <el-table-column prop="plan" label="套餐" sortable />
                    <el-table-column
                      prop="new_trial"
                      label="新增试用"
                      sortable
                    />
                    <el-table-column prop="new_pay" label="新增付费" sortable>
                      <template #default="scope">
                        <span
                          :style="{
                            color: scope.row.new_pay !== 0 ? 'red' : 'black',
                          }"
                        >
                          {{ scope.row.new_pay }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="total_pay_money"
                      label="新增付费收入"
                      sortable
                    />
                    <el-table-column
                      prop="trial_to_paid"
                      label="试用转正"
                      sortable
                    >
                      <template #default="scope">
                        <span
                          :style="{
                            color:
                              scope.row.trial_to_paid !== 0 ? 'red' : 'black',
                          }"
                        >
                          {{ scope.row.trial_to_paid }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="total_trial_to_paid_money"
                      label="试用转正收入"
                      sortable
                    />
                    <el-table-column
                      prop="trial_rate"
                      label="试用率"
                      :formatter="formatPercentage"
                      sortable
                    />
                    <el-table-column
                      prop="trial_to_paid_rate"
                      label="转正率"
                      :formatter="formatPercentage"
                      sortable
                    />
                  </el-table>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column prop="campaign_id" label="广告系列" sortable>
          <template #default="scope">
            <a
              @click="callParentMethod(scope.row.campaign_id)"
              style="
                color: #409eff;
                text-decoration: underline;
                cursor: pointer;
              "
              >{{ scope.row.campaign_id }}</a
            >
          </template>
        </el-table-column>
        <el-table-column prop="campaign_name" label="系列名称" sortable />
        <el-table-column
          prop="campaign_status"
          label="投放状态"
          align="center"
          sortable
        >
          <template #default="scope">
            <el-tag
              v-if="scope.row.campaign_status"
              :type="
                scope.row.campaign_status === 'ACTIVE' ? 'success' : 'danger'
              "
            >
              {{ scope.row.campaign_status }}
            </el-tag>
            <span v-else>{{ scope.row.campaign_status }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="new_day" label="新增设备" sortable />
        <el-table-column prop="details" label="详情" :min-width="500">
          <template #default="scope">
            <el-table :data="scope.row.details" style="width: 100%" :fit="true">
              <el-table-column prop="plan" label="套餐" sortable />
              <el-table-column prop="new_trial" label="新增试用" sortable />
              <el-table-column prop="new_pay" label="新增付费" sortable>
                <template #default="scope">
                  <span
                    :style="{
                      color: scope.row.new_pay !== 0 ? 'red' : 'black',
                    }"
                  >
                    {{ scope.row.new_pay }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="total_pay_money"
                label="新增付费收入"
                sortable
              />

              <el-table-column prop="trial_to_paid" label="试用转正" sortable>
                <template #default="scope">
                  <span
                    :style="{
                      color: scope.row.trial_to_paid !== 0 ? 'red' : 'black',
                    }"
                  >
                    {{ scope.row.trial_to_paid }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="total_trial_to_paid_money"
                label="试用转正收入"
                sortable
              />
              <el-table-column
                prop="trial_rate"
                label="试用率"
                :formatter="formatPercentage"
                sortable
              />
              <el-table-column
                prop="trial_to_paid_rate"
                label="转正率"
                :formatter="formatPercentage"
                sortable
              />
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="adset_show">
      <el-row>
        <el-button @click="back_to_fb_campaign_data">返回广告系列</el-button>
      </el-row>
      <el-table
        id="fb_adset_table"
        :data="site_plan_data"
        style="width: 100%"
        @expand-change="handleExpandChange"
        :cell-style="fb_adset_cellStyle"
        v-loading="campaign_plan_loading"
        element-loading-text="加载中..."
      >
        <el-table-column type="expand">
          <template #default="props">
            <el-table
              :data="props.row.day_details"
              style="width: 100%"
              :fit="true"
            >
              <el-table-column prop="campaign_id" label="广告系列" sortable />
              <el-table-column prop="campaign_name" label="系列名称" sortable />
              <el-table-column prop="new_day" label="新增设备" sortable />
              <el-table-column prop="details" label="详情" :min-width="500">
                <template #default="scope">
                  <el-table :data="scope.row.details" :fit="true">
                    <el-table-column prop="plan" label="套餐" sortable />
                    <el-table-column
                      prop="new_trial"
                      label="新增试用"
                      sortable
                    />
                    <el-table-column prop="new_pay" label="新增付费" sortable>
                      <template #default="scope">
                        <span
                          :style="{
                            color: scope.row.new_pay !== 0 ? 'red' : 'black',
                          }"
                        >
                          {{ scope.row.new_pay }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="total_pay_money"
                      label="新增付费收入"
                      sortable
                    />
                    <el-table-column
                      prop="trial_to_paid"
                      label="试用转正"
                      sortable
                    >
                      <template #default="scope">
                        <span
                          :style="{
                            color:
                              scope.row.trial_to_paid !== 0 ? 'red' : 'black',
                          }"
                        >
                          {{ scope.row.trial_to_paid }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="total_trial_to_paid_money"
                      label="试用转正收入"
                      sortable
                    />
                    <el-table-column
                      prop="trial_rate"
                      label="试用率"
                      :formatter="formatPercentage"
                      sortable
                    />
                    <el-table-column
                      prop="trial_to_paid_rate"
                      label="转正率"
                      :formatter="formatPercentage"
                      sortable
                    />
                  </el-table>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column prop="campaign_id" label="广告系列" sortable>
          <template #default="scope">
            <a
              @click="callParentMethod(scope.row.campaign_id)"
              style="
                color: #409eff;
                text-decoration: underline;
                cursor: pointer;
              "
              >{{ scope.row.campaign_id }}</a
            >
          </template>
        </el-table-column>
        <el-table-column prop="campaign_name" label="系列名称" sortable />
        <el-table-column prop="new_day" label="新增设备" sortable />
        <el-table-column prop="details" label="详情" :min-width="500">
          <template #default="scope">
            <el-table :data="scope.row.details" style="width: 100%" :fit="true">
              <el-table-column prop="plan" label="套餐" sortable />
              <el-table-column prop="new_trial" label="新增试用" sortable />
              <el-table-column prop="new_pay" label="新增付费" sortable>
                <template #default="scope">
                  <span
                    :style="{
                      color: scope.row.new_pay !== 0 ? 'red' : 'black',
                    }"
                  >
                    {{ scope.row.new_pay }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="total_pay_money"
                label="新增付费收入"
                sortable
              />
              <el-table-column prop="trial_to_paid" label="试用转正" sortable>
                <template #default="scope">
                  <span
                    :style="{
                      color: scope.row.trial_to_paid !== 0 ? 'red' : 'black',
                    }"
                  >
                    {{ scope.row.trial_to_paid }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="total_trial_to_paid_money"
                label="试用转正收入"
                sortable
              />
              <el-table-column
                prop="trial_rate"
                label="试用率"
                :formatter="formatPercentage"
                sortable
              />
              <el-table-column
                prop="trial_to_paid_rate"
                label="转正率"
                :formatter="formatPercentage"
                sortable
              />
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="ad_show">
      <el-row>
        <el-button @click="back_to_fb_adset_data">返回广告单元</el-button>
      </el-row>
      <el-table
        id="fb_ad_table"
        :data="ad_plan_data"
        style="width: 100%"
        v-loading="campaign_plan_loading"
        element-loading-text="加载中..."
      >
        <el-table-column type="expand">
          <template #default="props">
            <el-table
              :data="props.row.day_details"
              style="width: 100%"
              :fit="true"
            >
              <el-table-column prop="campaign_id" label="广告系列" sortable />
              <el-table-column prop="campaign_name" label="系列名称" sortable />
              <el-table-column prop="new_day" label="新增设备" sortable />
              <el-table-column prop="details" label="详情" :min-width="500">
                <template #default="scope">
                  <el-table :data="scope.row.details" :fit="true">
                    <el-table-column prop="plan" label="套餐" sortable />
                    <el-table-column
                      prop="new_trial"
                      label="新增试用"
                      sortable
                    />
                    <el-table-column prop="new_pay" label="新增付费" sortable>
                      <template #default="scope">
                        <span
                          :style="{
                            color: scope.row.new_pay !== 0 ? 'red' : 'black',
                          }"
                        >
                          {{ scope.row.new_pay }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="total_pay_money"
                      label="新增付费收入"
                      sortable
                    />
                    <el-table-column
                      prop="trial_to_paid"
                      label="试用转正"
                      sortable
                    >
                      <template #default="scope">
                        <span
                          :style="{
                            color:
                              scope.row.trial_to_paid !== 0 ? 'red' : 'black',
                          }"
                        >
                          {{ scope.row.trial_to_paid }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="total_trial_to_paid_money"
                      label="试用转正收入"
                      sortable
                    />
                    <el-table-column
                      prop="trial_rate"
                      label="试用率"
                      :formatter="formatPercentage"
                      sortable
                    />
                    <el-table-column
                      prop="trial_to_paid_rate"
                      label="转正率"
                      :formatter="formatPercentage"
                      sortable
                    />
                  </el-table>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column prop="campaign_id" label="广告系列" sortable>
        </el-table-column>
        <el-table-column prop="campaign_name" label="系列名称" sortable />
        <el-table-column prop="new_day" label="新增设备" sortable />
        <el-table-column prop="details" label="详情" :min-width="500" sortable>
          <template #default="scope">
            <el-table :data="scope.row.details" style="width: 100%" :fit="true">
              <el-table-column prop="plan" label="套餐" sortable />
              <el-table-column prop="new_trial" label="新增试用" sortable />
              <el-table-column prop="new_pay" label="新增付费" sortable>
                <template #default="scope">
                  <span
                    :style="{
                      color: scope.row.new_pay !== 0 ? 'red' : 'black',
                    }"
                  >
                    {{ scope.row.new_pay }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="total_pay_money"
                label="新增付费收入"
                sortable
              />
              <el-table-column prop="trial_to_paid" label="试用转正" sortable>
                <template #default="scope">
                  <span
                    :style="{
                      color: scope.row.trial_to_paid !== 0 ? 'red' : 'black',
                    }"
                  >
                    {{ scope.row.trial_to_paid }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="total_trial_to_paid_money"
                label="试用转正收入"
                sortable
              />
              <el-table-column
                prop="trial_rate"
                label="试用率"
                :formatter="formatPercentage"
                sortable
              />
              <el-table-column
                prop="trial_to_paid_rate"
                label="转正率"
                :formatter="formatPercentage"
                sortable
              />
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { toRefs, defineComponent } from "vue";

export default defineComponent({
  emits: [
    "get_fb_site_data_source",
    "get_fb_ad_data_source",
    "back_to_fb_campaign_data",
    "back_to_fb_adset_data",
  ],
  props: {
    get_fb_type: Number,
    fb_campaign_plan_data: Array,
    fb_site_plan_data: Array,
    fb_ad_plan_data: Array,
    fb_campaign_show: Boolean,
    fb_adset_show: Boolean,
    fb_ad_show: Boolean,
    fb_renew_show: Boolean,
    current_fb_campaign_id: String,
    current_fb_site_id: String,
    fb_campaign_plan_loading: Boolean,
  },
  name: "FbSubTable",
  methods: {
    formatNormal(row, column) {
      if (row[column.property] == null) {
        return "NA";
      }
      return `${row[column.property]}`;
    },
    formatPercentage(row, column) {
      if (row[column.property] != 0) {
        if (row[column.property] == null) {
          return "NA";
        } else {
          return `${row[column.property].toFixed(2)}%`;
        }
      }
      return `${row[column.property]}`;
    },
    handleExpandChange(row, expandedRows) {
      console.log("Row expanded:", row);
      console.log("Currently expanded rows:", expandedRows);
    },
    callParentMethod(campaign_id) {
      console.log(campaign_id);
      if (this.fb_type == 1) {
        this.$emit("get_fb_site_data_source", campaign_id);
      } else {
        this.$emit("get_fb_ad_data_source", campaign_id);
      }
    },
    back_to_fb_campaign_data() {
      this.$emit("back_to_fb_campaign_data");
    },
    back_to_fb_adset_data() {
      this.$emit("back_to_fb_adset_data");
    },
    fb_cellStyle({ row }) {
      if (row.campaign_id == this.current_campaign_id) {
        return {
          background: "#d0ebff",
        };
      }
      return "";
    },
    fb_adset_cellStyle({ row }) {
      if (row.campaign_id == this.current_site_id) {
        return {
          background: "#d0ebff",
        };
      }
      return "";
    },
  },
  mounted() {},
  setup(props) {
    const {
      get_fb_type,
      fb_campaign_plan_data,
      fb_site_plan_data,
      fb_ad_plan_data,
      fb_campaign_show,
      fb_adset_show,
      fb_ad_show,
      fb_renew_show,
      current_fb_campaign_id,
      current_fb_site_id,
      fb_campaign_plan_loading,
    } = toRefs(props);
    var fb_type = get_fb_type;
    var campaign_plan_data = fb_campaign_plan_data;
    var site_plan_data = fb_site_plan_data;
    var ad_plan_data = fb_ad_plan_data;
    var campaign_show = fb_campaign_show;
    var adset_show = fb_adset_show;
    var ad_show = fb_ad_show;
    var show_details = fb_renew_show;
    var current_campaign_id = current_fb_campaign_id;
    var current_site_id = current_fb_site_id;
    var campaign_plan_loading = fb_campaign_plan_loading;
    return {
      fb_type,
      campaign_plan_data,
      site_plan_data,
      ad_plan_data,
      campaign_show,
      adset_show,
      ad_show,
      show_details,
      current_campaign_id,
      current_site_id,
      campaign_plan_loading,
    };
  },
});
</script>
<style scoped>
.my_table {
  zoom: 78%;
}

th {
  font-size: 16px;
  padding: 10px;
}

td {
  font-size: 16px;
  padding: 10px;
}

.head_bg {
  background-color: #f5f7fa;
  color: #909399;
  width: 170px;
}

thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

th,
td {
  border: 1px solid #909399;
  text-align: center;
}

.collapse {
  border-collapse: collapse;
}
</style>