<template>
    <div class="my_table">
        <el-form :inline="true" :model="user_form" style="width:100%" label-position="left">
            <el-row>
                <el-col :span="4">
                    <div style="display: flex">
                        <div> <el-form-item label="用户邮箱">
                                <el-input placeholder="请输入用户邮箱地址" clearable v-model="user_form.email" />
                            </el-form-item> </div>
                    </div>
                </el-col>
                <el-col :span="2">
                    <el-form-item>
                        <el-button type="primary" @click="get_user_data" style="width: 180px;">查 询</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <h3>基础信息</h3>
        <el-row>
            <el-col :span="4" style="padding:10px">
                <div>
                    邮箱: {{ userInfo.email }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                <div>
                    UID: {{ userInfo.uid }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                <div>
                    上次登录时间: {{ userInfo.lastLogin }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                <div>
                    注册时间: {{ userInfo.createTime }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                <div>
                    广告系列: {{ userInfo.campaign_id }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                <div>
                    搜索词: {{ userInfo.site_id }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                <div>
                    安装时间: {{ userInfo.install_time }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                <div>
                    商店页地区: {{ userInfo.shop_country }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                <div>
                    运营商地区: {{ userInfo.mcc }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                <div>
                    会员信息: {{ userInfo.vip_desc }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                <div>
                    订阅平台: {{ userInfo.sub_platform }}
                </div>
            </el-col>
            <el-col :span="4" style="padding:10px">
                过期时间: {{ userInfo.expireTime }}
            </el-col>
            <el-col :span="4" style="padding:10px">
                订阅状态: {{ userInfo.sub_status }}
            </el-col>
        </el-row>
        <div>
            <el-divider content-position="center">连接详情</el-divider>
            <el-tabs style="width:100%" v-model="active_index" @tab-change="onTabChange">
                <el-tab-pane label="连接记录" name="1">
                    <el-tabs v-model="conn_active" type="border-card">
                        <el-tab-pane label="图表" name="1">
                            <div style="display:flex">
                                <el-row id="conn_chart">
                                </el-row>
                                <el-row id="conn_group_chart">
                                </el-row>
                            </div>
                            <div style="display:flex">
                                <el-row id="conn_city_chart">
                                </el-row>
                                <el-row id="conn_mode_chart">
                                </el-row>
                            </div>
                            <div style="display:flex">
                                <el-row id="os_chart">
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="表格" name="2">

                            <el-table table-layout="auto" :data="conn_model.records" style="width: 100%"
                                max-height="800">
                                <el-table-column prop="sid" label="Sid" />
                                <el-table-column prop="occur_time" label="连接日期" />
                                <el-table-column prop="ip" label="IP地址" />
                                <el-table-column prop="p" label="包名" />
                                <el-table-column prop="dev" label="设备ID" />
                                <el-table-column prop="v" label="版本号" />
                                <el-table-column prop="mcc" label="运营商" />
                                <el-table-column prop="os" label="操作系统" />
                                <el-table-column prop="o" label="API版本" />
                                <el-table-column prop="c" label="国家" />
                                <el-table-column prop="l" label="语言" />
                                <el-table-column prop="m" label="型号" />
                                <el-table-column prop="sc" label="商店页" />
                                <el-table-column prop="auto" label="连接模式" />
                                <el-table-column prop="vid" label="服务器分组" />
                                <el-table-column prop="brand" label="厂商" />
                                <el-table-column prop="cid" label="城市" />
                                <el-table-column prop="cost" label="连接耗时" />
                                <el-table-column prop="success" label="连接结果" />
                                <el-table-column prop="cc" label="连接次数" />
                                <el-table-column prop="ts" label="服务器时间">
                                    <template #default="scope">
                                        <span :class="scope.row.is_error ? 'danger' : ''">{{ scope.row.ts }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </el-tab-pane>
                <el-tab-pane label="断连记录" name="2">
                    <el-tabs v-model="disc_active" type="border-card">
                        <el-tab-pane label="图表" name="2">
                            <div style="display:flex">
                                <el-row id="disc_group_chart">
                                </el-row>
                                <el-row id="disc_city_chart">
                                </el-row>
                            </div>
                            <div style="display:flex">
                                <el-row id="disc_mode_chart">
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="表格" name="3">
                            <el-table table-layout="auto" :data="disc_conn_model.records">
                                <el-table-column prop="sid" label="Sid" />
                                <el-table-column prop="occur_time" label="连接日期" />
                                <el-table-column prop="p" label="包名" />
                                <el-table-column prop="os" label="平台" />
                                <el-table-column prop="v" label="版本号" />
                                <el-table-column prop="mcc" label="运营商" />
                                <el-table-column prop="c" label="国家" />
                                <el-table-column prop="l" label="语言" />
                                <el-table-column prop="dev" label="设备ID" />
                                <el-table-column prop="o" label="API版本" />
                                <el-table-column prop="m" label="型号" />
                                <el-table-column prop="auto" label="连接模式" />
                                <el-table-column prop="vid" label="服务器分组" />
                                <el-table-column prop="cid" label="城市" />
                                <el-table-column prop="brand" label="厂商" />
                                <el-table-column prop="bcount" label="广告屏蔽次数" />
                                <el-table-column prop="rx" label="下载流量" />
                                <el-table-column prop="tx" label="上传流量" />
                                <el-table-column prop="times" label="连接时长" />
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </el-tab-pane>
                <el-tab-pane label="订阅记录" name="3">
                    <el-table table-layout="auto" :data="sub_model.records">
                        <el-table-column prop="productId" label="订阅套餐" />
                        <el-table-column prop="startTime" label="开始时间" />
                        <el-table-column prop="expireTime" label="结束时间" />
                        <el-table-column prop="currencyCode" label="支付货币" />
                        <el-table-column prop="priceAmount" label="支付金额" />
                        <el-table-column prop="isRefund" label="退款状态">
                            <template #default="scope">
                                <span :class="scope.row.isRefund == '是' ? 'danger' : ''">{{ scope.row.isRefund }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="subPlatform" label="订阅平台" />
                        <el-table-column prop="offer_type" label="推介优惠" />
                        <el-table-column prop="createTime" label="创建时间" />
                        <el-table-column prop="updateTime" label="更新时间" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="API记录" name="4">
                    <el-table table-layout="auto" :data="api_model.records">
                        <el-table-column prop="event_code" label="操作事件" />
                        <el-table-column prop="device_id" label="设备ID" />
                        <el-table-column prop="channel" label="渠道" />
                        <el-table-column prop="sub_channel" label="子渠道" />
                        <el-table-column prop="version" label="版本号" />
                        <el-table-column prop="platform" label="客户端" />
                        <el-table-column prop="mcc" label="运营商" />
                        <el-table-column prop="ip" label="IP地址" />
                        <el-table-column prop="package" label="包名" />
                        <el-table-column prop="site_id" label="搜索词" />
                        <el-table-column prop="campaign_id" label="广告系列" />
                        <el-table-column prop="country" label="国家" />
                        <el-table-column prop="city" label="城市" />
                        <el-table-column prop="ts" label="操作时间" />
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>

</template>

<script>
import {
    get_sub_records,
    get_user_conn_records,
    get_user_disc_conn_records,
    get_user_info,
    get_user_api_event_records
} from '../services/requests.js'
import * as echarts from 'echarts';
export default ({
    name: "UserInfo",
    data() {
        return {
            "userInfo": {
                "uid": "",
                "email": "",
                "lastLogin": "",
                "createTime": "",
                "campaign_id": "",
                "site_id": "",
                "mcc": "",
                "install_time": "",
                "shop_country": "",
                "vip_desc": "",
                "sub_status": "",
                "sub_platform": "",
                "expireTime": ""
            },
            conn_active: "1",
            disc_active: "2",
            user_form: {
                email: ""
            },
            active_index: "1",
            conn_model: {
                records: [],
                conn: {
                    "success": 0,
                    "failed": 0,
                    "conn_rate": 0
                }
            },
            api_model: {
                records: []
            },
            sub_model: {
                records: []
            },
            disc_conn_model: {
                records: []
            }
        }
    },
    methods: {
        onTabChange(tabName) {
            console.log("切换tab", tabName)
            if (this.user_form.email.length > 0) {
                if (tabName == "1") {
                    this.conn_model.records = []
                    this.get_conn_data()
                }
                if (tabName == "2") {
                    this.disc_conn_model.records = []
                    this.get_disc_conn_data()
                }
                if (tabName == "3") {
                    this.sub_model.records = []
                    this.get_sub_data()
                }
                if (tabName == "4") {
                    this.api_model.records = []
                    this.get_api_event_data()
                }
            }

        },
        load_pie_chart(chart_id, title, data) {
            console.log(chart_id, title, "加载数据", data)
            var chartDom = document.getElementById(chart_id);
            var myChart = echarts.getInstanceByDom(chartDom);
            if (myChart) {
                myChart.dispose(); // 销毁现有实例
            }
            // 获取屏幕宽度
            var screen_width = document.body.clientWidth
            var width = screen_width / 2
            var height = 400
            chartDom.style.width = width + 'px';
            chartDom.style.height = height + 'px';
            myChart = echarts.init(chartDom);
            var option = {
                title: {
                    text: title,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        data: data,
                        label: {
                            normal: {
                                show: true,
                                formatter: '{b}: {c}次 ( {d}% )'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            console.log(title, option);
            myChart.setOption(option);
            myChart.resize(width, height);
        },
        get_api_event_data() {
            get_user_api_event_records(this.user_form).then(
                res => {
                    var { code, msg, records } = res.data;
                    if (code == 200) {
                        this.$message.success("获取API日志成功")
                        console.log(records)
                        this.api_model.records = records;
                    }
                    else {
                        this.$message.error("获取API日志失败:" + msg)
                    }
                }
            )
        },
        get_disc_conn_data() {
            get_user_disc_conn_records(this.user_form).then(
                res => {
                    var {
                        code, msg, records,
                        disc_group_data,
                        disc_city_data,
                        disc_mode_data,
                    } = res.data;
                    if (code == 200) {
                        this.$message.success("获取断连记录成功")
                        console.log(records)
                        this.disc_conn_model.records = records;
                        this.load_pie_chart("disc_group_chart", "服务器分组占比", disc_group_data);
                        this.load_pie_chart("disc_city_chart", "连接城市占比", disc_city_data);
                        this.load_pie_chart("disc_mode_chart", "连接模式占比", disc_mode_data);
                    }
                    else {
                        this.$message.error("获取断连记录失败:" + msg)
                    }
                }
            )
        },
        get_sub_data() {
            get_sub_records(this.user_form).then(
                res => {
                    var { code, msg, records } = res.data;
                    if (code == 200) {
                        this.$message.success("获取订阅记录成功")
                        this.sub_model.records = records;
                        console.log(this.sub_model.records)
                    }
                    else {
                        this.$message.error("获取订阅记录失败:" + msg)
                    }
                }
            )
        },
        get_user_data() {
            if (this.user_form.email.length > 0) {
                get_user_info(this.user_form).then(
                    res => {
                        var { code, msg, userInfo } = res.data;
                        if (code == 200) {
                            this.$message.success("获取用户详情成功")
                            this.userInfo = userInfo;
                        }
                        else {
                            this.$message.error("获取用户详情失败:" + msg)
                        }
                    }
                )
                this.get_conn_data();
            } else {
                this.$message.error("邮箱不能为空")
            }

        },
        get_conn_data() {
            get_user_conn_records(this.user_form).then(
                res => {
                    var {
                        code, msg, records, conn, conn_data,
                        conn_group_data,
                        conn_city_data,
                        conn_mode_data,
                        os_data
                    } = res.data;
                    if (code == 200) {
                        this.$message.success("用户连接记录成功")
                        this.conn_model.records = records;
                        this.conn_model.conn = conn;
                        this.load_pie_chart("conn_chart", "连接结果占比", conn_data);
                        this.load_pie_chart("conn_group_chart", "服务器分组占比", conn_group_data);
                        this.load_pie_chart("conn_city_chart", "连接城市占比", conn_city_data);
                        this.load_pie_chart("conn_mode_chart", "连接模式占比", conn_mode_data);
                        this.load_pie_chart("os_chart", "操作系统占比", os_data);
                    }
                    else {
                        this.$message.error("获取连接记录失败:" + msg)
                    }
                }
            )
        }
    },
    mounted() {

    }
})
</script>
<style scoped>
.my_table {
    padding: 20px;
}

.danger {
    color: red;
}
</style>