<template>
    <div class="my_table">
        <el-form :inline="true"  style="width:100%" label-position="left">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="选择心愿类型" style="width:70%">
                        <el-select v-model="target" placeholder="心愿类型" filterable allow-create clearable>
                            <el-option v-for="(item, index) in wish_list" :key="index" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                      </el-form-item> 
                </el-col>
                <el-col :span="6">
                <el-form-item label="选择统计类型" style="width:70%">
                    <el-select v-model="mode" placeholder="统计类型" filterable allow-create clearable>
                        <el-option v-for="(item, index) in mode_list" :key="index" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    </el-form-item> 
                </el-col>

                <el-col :span="6">
                  <el-form-item label="时间范围">
                    <el-date-picker v-model="date" type="daterange" unlink-panels size="default"
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts"
                      format="YYYY-MM-DD" value-format="YYYY-MM-DD" style="height:32px" />
                  </el-form-item>
                </el-col>


                <el-col :span="2">
                  <el-form-item>
                    <el-button type="primary" @click="get_data" style="width: 180px;">查 询</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
        </el-form>

        <el-tabs style="width:100%" v-model="active_index" @tab-change="onTabChange" >
                <el-tab-pane label="心愿单图表" name="1">
                    <div style="margin-top:10px;" >
                        <div id="wish_chart" style="width:100%; height:100%">
                        </div>
                    </div>
                </el-tab-pane>   
                <el-tab-pane label="心愿单评论" name="2">
                    <el-table table-layout="auto" :data="records">
                        <el-table-column prop="create_time" label="日期" />
                        <el-table-column prop="country_code" label="服务器地区" />
                        <el-table-column prop="mcc" label="用户地区" />
                        <el-table-column prop="n" label="不满意选项" />
                        <el-table-column prop="o" label="不满意内容" />
                        <el-table-column prop="comments" label="评论内容" />
                        <el-table-column prop="email" label="用户邮箱" />
                        <el-table-column prop="email_typed" label="填写邮箱" />
                    </el-table>
                </el-tab-pane>
            </el-tabs>
    
    </div>

</template>

<script>
    import {
        get_wish_data_chart,
        get_wish_comments
    } from '../services/requests.js'
    import * as echarts from 'echarts';
    // 获取当前日期  和 7天前的日期
    const getDate = () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 3)
      const start_date = start.toISOString().split("T")[0];
      const end_date = end.toISOString().split("T")[0];
      return [start_date, end_date]
    }
    const date_range = getDate()
    export default  ({
        name: "Wish",
        data(){
            return {
                target: "streaming",
                date: date_range,
                start_date: date_range[0],
                end_date: date_range[1],
                mode_list:[
                    {
                        label: "uv",
                        value: "uv"
                    },
                    {
                        label: "pv",
                        value: "pv"
                    }
                ],
                mode: "uv",
                records: [],
                wish_list:[
                    {
                        label: "游戏",
                        value: "gaming"
                    },
                    {
                        label: "下载",
                        value: "downloads"
                    },
                    {
                        label: "流媒体",
                        value: "streaming"
                    },
                    {
                        label: "地区解锁",
                        value: "region"
                    },
                    {
                        label: "社交媒体",
                        value: "media"
                    },
                    {
                        label: "其他",
                        value: "others"
                    }
                ],
                active_index: "1",
                mychart: null,
                shortcuts: [{
                    text: '过去一周',
                    value: () => {
                      const end = new Date()
                      const start = new Date()
                      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                      return [start, end]
                    },
                  },
                  {
                    text: '过去一个月',
                    value: () => {
                      const end = new Date()
                      const start = new Date()
                      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                      return [start, end]
                    },
                  },
                  {
                    text: '过去三个月',
                    value: () => {
                      const end = new Date()
                      const start = new Date()
                      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                      return [start, end]
                    },
                  }],
             }
        },
        methods:{
            onTabChange(tabName){
                if(tabName == "2"){
                    this.load_comment_data();
                }else{
                    this.load_wish_data();
                }
            },
            load_wish_data(){
                get_wish_data_chart({
                    "target": this.target,
                    "mode": this.mode,
                    "start_date": this.date[0],
                    "end_date": this.date[1]
                }).then(
                    res=>{
                        var {
                            code, msg , target, country_list, date_list, series
                          } = res.data;
                         if(code == 200){
                            this.$message.success("查询心愿单数据成功")
                            console.log(series)
                            series.forEach(function(item, index){
                                console.log(index)
                                item.label["formatter"] = function (params) {
                                    if (params.value > 0) {
                                        return params.value;
                                    } else {
                                        return '';
                                    }
                                }
                            })
                            this.load_chart(target, country_list, date_list, series);
                         }
                         else{
                            this.$message.error("查询心愿单数据失败:" + msg)
                         }
                    }
                )
            },
            load_comment_data(){
                get_wish_comments({
                    "mode": this.mode,
                    "start_date": this.date[0],
                    "end_date": this.date[1]
                }).then(
                    res=>{
                        var {
                            code, msg , records
                          } = res.data;
                         if(code == 200){
                            this.$message.success("查询心愿单评论成功")
                            this.records = records
                         }else{
                            this.$message.error(msg)
                         }
                    }
                )
            },
            load_chart(title, country_list, date_list, series) {
                var chartDom = document.getElementById("wish_chart");
                // 获取屏幕宽度
                var screen_width = document.body.clientWidth
                var width = screen_width 
                var height = 800
                chartDom.style.width = width + 'px';
                chartDom.style.height = height + 'px';
                if (this.myChart) {
                    this.myChart.dispose()
                    this.myChart = null
                }
                this.myChart = echarts.init(chartDom);
                var  option = {
                        title: {
                            text: title
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: country_list
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        toolbox: {
                            feature: {
                            saveAsImage: {}
                            }
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: date_list
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: series
                    };
                    console.log(option)
                    this.myChart.setOption(option);
                    this.myChart.resize(width, height);
            },
            get_data(){
                if(this.active_index == "2"){
                    this.load_comment_data();
                }else{
                    this.load_wish_data();
                }
            }
        },
        mounted(){
        }
    })
</script>
<style scoped>
    .my_table{
        padding:20px;
    }
    .danger{
        color: red;
    }
</style>