<template>
  <div class="container">
    <h3>奖励时长券生成</h3>
    <div class="coupon-generate">
      <div class="email">
        <p>用户邮箱:</p>
        <el-input
          v-model="emailInput"
          style="width: 240px"
          placeholder="请输入用户邮箱"
          clearable
        />
      </div>
      <div class="type">
        <p>奖券类型:</p>
        <el-select
          v-model="couponValue"
          clearable
          placeholder="请选择奖券类型"
          style="width: 240px"
        >
          <el-option
            v-for="item in couponOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="remark">
        <p>备注:</p>
        <el-input
          v-model="remarkInput"
          style="width: 540px"
          placeholder="请输入备注"
          clearable
        />
      </div>
      <el-button type="primary" @click="sendReward()">生成并发送</el-button>
    </div>
    <h3>发放记录</h3>
    <div class="issuance-record">
      <div class="status">
        <p>领取状态:</p>
        <el-select
          v-model="statusValue"
          clearable
          placeholder="请选择领取状态"
          style="width: 240px"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="type">
        <p>奖券类型:</p>
        <el-select
          v-model="couponValue2"
          clearable
          placeholder="请选择奖券类型"
          style="width: 240px"
        >
          <el-option
            v-for="item in couponOptions2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="date">
        <p>生成日期:</p>
        <el-date-picker
          unlink-panels 
          style="height: 32px"
          v-model="dateValue"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :shortcuts="shortcuts"
          :default-value="[new Date(2024, 6, 1), new Date(2024, 7, 1)]"
        />
      </div>
      <el-button type="primary" @click="queryReward()">筛选</el-button>
    </div>
    <div class="status-container">
      <p>共发放: {{ff_total}}张</p>
      <p>已领取: {{lq_total}}张</p>
      <p>未领取: {{wlq_total}}张</p>
    </div>
    <el-table v-loading="loading" :data="tableData" border style="width: 100%; max-height: 400px; overflow-y: auto;">
      <el-table-column sortable prop="createTime" label="生成日期" />
      <el-table-column sortable prop="email" label="用户邮箱" />
      <el-table-column sortable prop="gift_time_type" label="奖券类型" />
      <el-table-column sortable prop="gift_time_status" label="奖券状态">
        <template #default="{ row }">
          <span :style="{ color: getStatusColor(row.gift_time_status) }">
            {{ row.gift_time_status }}
          </span>
        </template>
      </el-table-column>
      <el-table-column sortable prop="redeemTime" label="领取日期" />
      <el-table-column sortable prop="expireTime" label="过期日期" />
      <el-table-column sortable prop="remark" label="备注">
        <template #default="{ row }">
          <el-popover
            placement="top-start"
            :width="250"
            trigger="hover"
            :content="row.remark"
          >
            <template #reference>
              <span class="cell-remark">{{ row.remark }}</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>>
    </el-table>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
// import { send_reward, query_reward } from '../js/api.js'
import { send_reward, query_reward } from '../services/requests.js'
import { b64decode } from '../utils/way.js'
import { ElMessage, ElLoading  } from 'element-plus'

const emailInput = ref('')
const remarkInput = ref('')
const couponValue = ref('')
const couponValue2 = ref(0)
const statusValue = ref(0)
const end = new Date()
const start = new Date()
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
const dateValue = ref([start, end])
const ff_total = ref(0)
const lq_total = ref(0)
const wlq_total = ref(0)
const tableData = reactive([])
const loading = ref(false)
const couponOptions = [
  {
    value: 1,
    label: '3-day pass',
  },
  {
    value: 2,
    label: '7-day pass',
  },
  {
    value: 3,
    label: '14-day pass',
  },
  {
    value: 4,
    label: '30-day pass',
  },
]
const couponOptions2 = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '3-day pass',
  },
  {
    value: 2,
    label: '7-day pass',
  },
  {
    value: 3,
    label: '14-day pass', 
  },
  {
    value: 4,
    label: '30-day pass',
  },
]

const shortcuts = [
  {
    text: '过去一周',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    },
  },
  {
    text: '过去一个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: '过去三个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    },
  },
]
const statusOptions = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '未领取',
  },
  {
    value: 2,
    label: '已领取',
  },
  {
    value: 3,
    label: '已失效',
  },
]

const getStatusColor = (status) => {
//   console.log(status);
  if (status === '已领取') {
    return '#64bc39';
  } else if (status === '已失效') {
    return '#ec6868';
  } else {
    return '';
  }
}

const sendReward = () => {
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  if ( emailInput.value != '' && couponValue.value != '' ) {
    send_reward(emailInput.value, couponValue.value, remarkInput.value).then(res => {
      loading.close()
      var data = b64decode(res.data)
      var code = data.code
      if ( code == 200 ) { 
        ElMessage({
          message: `${emailInput.value}的${couponValue.value}发送成功`,
          type: 'success',
        })
      } else if ( code == 214 ) {
        ElMessage({
          message: `用户不存在，请检查重试`,
          type: 'error',
        })
      } else {
        ElMessage({
          message: `意外的错误，请检查重试`,
          type: 'error',
        })
      }
    }).catch( () => {
      loading.close()
    } )
  }
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // 获取年份、月份和日期
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以需要加1
    const day = ('0' + date.getDate()).slice(-2);

    // 拼接成 YYYY-MM-DD 格式
    return `${year}-${month}-${day}`;
}

const queryReward = () => {
  loading.value = true
  var startTime = formatDate(dateValue.value[0])
  var endTime = formatDate(dateValue.value[1])
//   console.log(statusValue.value, couponValue2.value, startTime, endTime);
  if ( startTime != '' && endTime != '' ) {
    query_reward(statusValue.value, couponValue2.value, startTime, endTime).then(res => {
      loading.value = false
      var data = b64decode(res.data)
      var code = data.code
      var details = data.details
      if ( code == 200 ) {
        ff_total.value = 0
        lq_total.value = 0
        wlq_total.value = 0
        tableData.length = 0
        for (let i = 0; i < details.length; i++) {
          const detail = { ...details[i] }; // 创建副本以避免直接修改原对象
          ff_total.value++
          switch (detail.gift_time_status) {
            case 0:
              detail.gift_time_status = '全部';
              break;
            case 1:
              detail.gift_time_status = '未领取';
              wlq_total.value++
              break;
            case 2:
              detail.gift_time_status = '已领取';
              lq_total.value++
              break;
            case 3:
              detail.gift_time_status = '已失效';
              break;
          }

          switch (detail.gift_time_type) {
            case 0:
              detail.gift_time_type = '全部';
              break;
            case 1:
              detail.gift_time_type = '3-day pass';
              break;
            case 2:
              detail.gift_time_type = '7-day pass';
              break;
            case 3:
              detail.gift_time_type = '14-day pass';
              break;
            case 4:
              detail.gift_time_type = '30-day pass';
              break;
          }
          tableData.push(detail);
        }
      }
    }).catch( () => {
      loading.value = false
    } )
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cell-remark {
  display: inline-block;
  max-width: 200px; /* 根据需要调整宽度 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.status-container {
  display: flex;
  align-items: center;
  justify-content: left;
}
.status-container p {
  margin-right: 20px;
}
.container {
  width: 1400px;
  padding: 20px;
  margin: 0 auto;
  border: 1px solid #ccc;
}
.coupon-generate, .issuance-record {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.email, .type, .remark, .status, .date {
  display: flex;
  align-items: center;
  justify-content: center;
}
.email p, .type p, .remark p, .status p, .date p {
  margin-right: 10px;
}

h3 {
  text-align: left;
  margin: 0px;
}
</style>
